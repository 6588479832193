<template>
  <container class="p-1">
    <div class="mb-3" v-if="permissions.SettingView">
      <DxGridWithSearch
        :pageSize="10"
        title="Lab Settings"
        :columns="columns"
        ref="settingsGrid"
        v-stream:content-ready="search$"
        :dataSource="labSettings"
        :columnChooser="true"
        :noDataText="'No Lab Settings Found'"
        gridName="labSettings"
      >
        <template v-slot:actions-cell="{ data }">
          <div class="button-group d-flex">
            <icon-button
              v-if="permissions.SettingCreateEdit"
              v-tooltip.left="'Edit setting.'"
              @click="editSetting(data)"
              class="m-auto text-primary pointer p-0"
              :icon="'pen-alt'"
            />

            <icon-button
              v-if="permissions.SettingAdmin"
              @click="editAdminSetting(data)"
              v-tooltip.left="'Edit admin settings.'"
              class="m-auto text-primary pointer p-0"
              :icon="'cogs'"
            />
          </div>
        </template>

        <template v-slot:headerCell="{ data }">
          <div class="accession-header">
            <b class="mr-auto">{{ data.column.caption }}</b>
          </div>
        </template>
      </DxGridWithSearch>
    </div>
    <div>
      <select-input
        class="mr-2 w-25"
        v-model="selectedType"
        :items="typeGroupsFilter"
        valueExpr="itemId"
      />
    </div>
    <div v-if="permissions.SettingView">
      <DxGridWithSearch
        :pageSize="10"
        title="Type Codes"
        :dataSource="typeCodes"
        :searchInput="false"
        :columnChooser="true"
        v-stream:initialized="typesGrid$"
        :columns="typesColumns"
        ref="typesGrid"
        :searchPanel="searchPanel"
        :toolbar="toolbar"
        :noDataText="'No Type Codes Found'"
        gridName="typeCodes"
      >
        <template v-slot:extraActions>
          <div class="d-flex">
            <icon-button
              class="btn btn-primary"
              v-if="permissions.SettingCreateEdit"
              @click="newTypeCode"
              icon="plus"
            >
              Add
            </icon-button>
          </div>
        </template>
        <template v-slot:actions-cell="{ data }">
          <!-- Should update once we have the contact endpoint -->
          <div class="button-group d-flex">
            <icon-button
              v-tooltip.left="'Edit type code.'"
              v-if="permissions.TypeCodeCreateEditAny"
              @click="editTypeCode(data)"
              class="m-auto text-primary pointer p-0"
              :icon="'pen-alt'"
            />
            <icon-button
              v-tooltip.left="'Edit type code admin.'"
              v-if="permissions.TypeCodeAdmin"
              @click="editTypeCodeAdmin(data)"
              class="m-auto text-primary pointer p-0"
              :icon="'cogs'"
            />
            <icon-button
              v-if="permissions.TypeCodeDelete"
              v-tooltip.left="'Delete type code.'"
              @click="handleDelete(data)"
              class="m-auto text-danger pointer p-0"
              :icon="'trash-alt'"
            />

            <!-- </router-link> -->
          </div>
        </template>

        <template v-slot:headerCell="{ data }">
          <div class="accession-header">
            <b class="mr-auto">{{ data.column.caption }}</b>
          </div>
        </template>
      </DxGridWithSearch>
    </div>
    <Modal :status="isLabAdminEdit" @close="cancelEdit">
      <div>
        <h3>Administer Lab Setting</h3>
        <form
          @submit.prevent="saveLabSetting"
          class="container"
          v-shortkey="saveShortKey"
          @shortkey="saveLabSetting"
        >
          <div class="row mx-0">
            <label class="col-sm-3 col-form-label">
              <b>Group:</b>
            </label>
            <div class="col-sm-9">
              {{ labSetting.group ? labSetting.group.displayName : "N/A" }}
            </div>
          </div>
          <div class="row mx-0">
            <label class="col-sm-3 col-form-label">
              <b>Setting:</b>
            </label>
            <div class="col-sm-9">
              {{ labSetting.settingDisplayName }}
            </div>
          </div>
          <div class="form-group row mx-0">
            <div class="col-sm-3">
              <b>Is Private:</b>
            </div>
            <div class="col-sm-9">
              <div class="form-check">
                <input
                  class="form-check-input position-static"
                  type="checkbox"
                  id="isPrivate"
                  v-model="labSetting.isPrivate"
                />
              </div>
            </div>
          </div>
          <div class="form-group row mx-0">
            <div class="col-sm-3">
              <b>Description:</b>
            </div>
            <div class="col-sm-9">
              <textarea
                class="form-control"
                id="descriptionSetting"
                rows="3"
                v-model="labSetting.description"
              ></textarea>
            </div>
          </div>
          <div class="form-group row mx-0">
            <div class="col-sm-3">
              <b>Keywords:</b>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="keywordsSetting"
                v-model="labSetting.keywords"
              />
            </div>
          </div>
          <div class="form-group row mx-0">
            <label for="groupId" class="col-sm-3">
              <b>Group</b>
            </label>
            <SelectInput
              class="col-sm-9"
              :noLabel="true"
              displayExpr="displayName"
              v-model="labSetting.groupId"
              :dataSource="groupsSearch"
              :validator="$v.labSetting.groupId"
            />
          </div>
          <div class="row mx-0 my-4 align-items-center justify-content-center">
            <span style="color: red; font-style: italic; font-size: 13px"
              >Note: Lab Setting changes will take effect once a user re-logins into the
              system.</span
            >
          </div>
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <div>
              <button class="btn btn-primary mx-2" type="submit">Save</button>
              <button class="btn btn-danger mx-2" type="button" @click="cancelEdit">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
    <Modal :status="isLabEdit" @close="cancelEdit">
      <div>
        <h3>Edit Lab Setting</h3>
        <div :class="useLargeContainer ? 'largeLabSettingsContainer' : 'labSettingsContainer'">
          <div class="flex mx-0">
            <div class="col-2">
              <b>Group:</b>
            </div>
            <div class="col-3 mx-3">
              {{ labSetting.group ? labSetting.group.displayName : "N/A" }}
            </div>
          </div>
          <div class="d-flex mx-0">
            <div class="col-2">
              <b>Setting:</b>
            </div>
            <div class="col mx-3">
              {{ labSetting.settingDisplayName ? labSetting.settingDisplayName : "" }}
            </div>
          </div>
          <div class="d-flex mx-0 align-items-center">
            <label for="value" class="col-2 mt-4">
              <b>Value:</b>
            </label>
            <!-- Dynamically loading a component representation of an input see @computed-labSettingValueProps -->
            <component
              class="col mx-3"
              id="value"
              :noLabel="true"
              :is="labSettingValueProps.is"
              v-bind="labSettingValueProps"
              name="value"
              v-model="labSetting.value"
              :key="labSetting.id"
              @changeJSON="changeJSON"
            />
          </div>
          <div v-if="labSetting.groupId === null" class="d-flex mx-0"></div>
          <div class="d-flex flex-column mx-0 my-4 align-items-center justify-content-center">
            <span
              v-if="labSettingValueProps.validationMessage"
              style="color: red; font-style: italic; font-size: 14px"
              class="d-block mb-2"
            >
              {{ labSettingValueProps.validationMessage }}
            </span>
            <span class="d-block" style="color: red; font-style: italic; font-size: 14px"
              >Note: Lab Setting changes will take effect once a user re-logins into the
              system.</span
            >
          </div>
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <div>
              <button
                class="btn btn-primary mx-2"
                @click="saveLabSetting"
                v-shortkey="saveShortKey"
                @shortkey="saveLabSetting"
              >
                Save
              </button>
              <button class="btn btn-danger mx-2" type="button" @click="cancelEdit">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal :status="isTypeAdminEdit" @close="cancelEdit">
      <div>
        <h3>Administer Type</h3>
        <form
          @submit.prevent="saveTypeCode"
          v-shortkey="saveShortKey"
          @shortkey="saveTypeCode"
          style="min-width: 450px"
        >
          <div class="row mx-0">
            <div class="col-sm-3">
              <b>Type:</b>
            </div>
            <div class="col-sm-9">
              {{ typeCode.type ? typeCode.type.displayName : "N/A" }}
            </div>
          </div>
          <div class="form-group row mx-0">
            <div class="col-sm-3">
              <b>Description:</b>
            </div>
            <div class="col-sm-9">
              <textarea
                class="form-control"
                id="descriptionSetting"
                rows="3"
                v-model="typeCode.description"
              ></textarea>
            </div>
          </div>
          <div class="form-group row mx-0">
            <div class="col-sm-3">
              <b>Keywords:</b>
            </div>
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                id="keywordsSetting"
                v-model="typeCode.keywords"
              />
            </div>
          </div>
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <div>
              <button class="btn btn-primary mx-2" type="submit">Save</button>
              <button class="btn btn-danger mx-2" type="button" @click="cancelEdit">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
    <Modal :status="isTypeEdit" @close="cancelEdit">
      <div>
        <h3>{{ typeCode.id === undefined ? "Add" : "Edit" }} Type Code</h3>
        <form
          @submit.prevent="saveTypeCode"
          v-shortkey="saveShortKey"
          @shortkey="saveTypeCode"
          class="container"
        >
          <div class="row mx-0 align-items-center mt-2">
            <div class="col-2">
              <b>Type:</b>
            </div>
            <SelectInput
              v-if="typeCode.id === undefined"
              v-focus
              :noLabel="true"
              required
              class="col-6 pl-1"
              style="min-height: inherit"
              name="typeId"
              v-model="typeCode.typeId"
              :items="typeGroups.filter(e => e.id !== null)"
            />
            <SelectInput
              v-else
              :noLabel="true"
              required
              name="typeId"
              class="col-4"
              style="min-height: inherit"
              v-model="typeCode.typeId"
              :disabled="true"
              :items="typeGroups"
            />
          </div>
          <div class="row my-2">
            <div class="col-2">
              <b>Code:</b>
            </div>
            <TextInput
              name="code"
              :noLabel="true"
              class="col-6"
              type="text"
              v-model="typeCode.code"
            />
          </div>
          <div class="row my-2">
            <div class="col-2">
              <b>Display Name:</b>
            </div>
            <TextInput
              :noLabel="true"
              v-focus
              class="col-6"
              required
              type="text"
              name="displayName"
              v-model="typeCode.displayName"
            />
          </div>
          <div v-if="typeCode.typeId === 110" class="row my-2">
            <div class="col-2">
              <label for="flag1">
                <b>{{ flag1Label }}:</b>
              </label>
            </div>
            <div class="col-4 d-flex">
              <simple-select-input :items="diagnosisColors" name="flag1" v-model="typeCode.flag1" />
              <span
                v-if="typeCode.flag1"
                :class="{
                  [`bg-${typeCode.flag1}`]: Boolean(typeCode.flag1),
                  'align-self-end rounded text-white mx-2 p-1': true
                }"
                >{{ typeCode.displayName || "Color" }}</span
              >
            </div>
            <div class="col-2">
              <label for="flag2">
                <b>Severity Level:</b>
              </label>
            </div>
            <NumberInput
              placeholder="Please use an integer."
              name="num1"
              class="col-4"
              type="text"
              v-model="typeCode.num1"
            />
          </div>
          <div v-else-if="typeCode.typeId === 19" class="row my-2">
            <div class="col-2">
              <label for="flag1">
                <b>{{ flag1Label }}:</b>
              </label>
            </div>
            <TextInput name="flag1" class="col-4" type="text" v-model="typeCode.flag1" />
            <div class="col-2">
              <label for="flag2">
                <b>File type:</b>
              </label>
            </div>
            <div class="col-4">
              <simple-select-input
                :items="fileExtensionOptions"
                name="flag2"
                v-model="typeCode.flag2"
              />
            </div>
          </div>
          <div v-else-if="typeCode.typeId === 112" class="row my-2">
            <div class="col-2">
              <label for="date1">
                <b>Effective On:</b>
              </label>
            </div>
            <DatePicker name="date1" class="col-4 my-1" type="text" v-model="typeCode.date1" />
            <div class="col-2">
              <label for="date2">
                <b>Expires On:</b>
              </label>
            </div>
            <DatePicker name="date2" class="col-4 my-1" type="text" v-model="typeCode.date2" />
            <div class="col-2">
              <label for="flag1">
                <b>CPT Code 1:</b>
              </label>
            </div>
            <!-- A different input will be displayed for the Diagnosis Summary type codes. -->
            <SelectInput
              :dataSource="cptCodes"
              name="flag1"
              class="col-4"
              type="text"
              displayExpr="code"
              valueExpr="code"
              v-model="typeCode.flag1"
            />
            <div class="col-2">
              <label for="flag2">
                <b>CPT Code 2:</b>
              </label>
            </div>
            <SelectInput
              :dataSource="cptCodes"
              name="flag2"
              class="col-4"
              type="text"
              valueExpr="code"
              displayExpr="code"
              v-model="typeCode.flag2"
            />
          </div>
          <div v-else class="row my-2">
            <div class="col-2 my-2">
              <label for="flag1">
                <b>{{ hasEffectiveOrExpiry ? "Effective On" : "Date 1" }}:</b>
              </label>
            </div>
            <DatePicker name="flag1" class="col-4 my-2" type="text" v-model="typeCode.date1" />
            <div class="col-2 my-2">
              <label for="flag1">
                <b>{{ hasEffectiveOrExpiry ? "Expiry On" : "Date 2" }}:</b>
              </label>
            </div>

            <DatePicker name="flag1" class="col-4 my-2" type="text" v-model="typeCode.date2" />
            <div class="col-2">
              <label for="flag1">
                <b>{{ flag1Label }}:</b>
              </label>
            </div>
            <!-- A different input will be displayed for the Diagnosis Summary type codes. -->
            <div class="col-4" v-if="typeCode.typeId === 103">
              <SelectInput
                name="flag1"
                :items="tagNoteTypeOptions"
                v-model="typeCode.flag1"
                valueExpr="valueName"
              />
            </div>
            <TextInput v-else name="flag1" class="col-4" type="text" v-model="typeCode.flag1" />
            <div class="col-2">
              <label for="flag2">
                <b>Flag 2:</b>
              </label>
            </div>
            <TextInput name="flag2" class="col-4" type="text" v-model="typeCode.flag2" />
            <div class="col-2 my-2">
              <label for="num1">
                <b>{{ [104, 105].includes(typeCode.typeId) ? "Priority Sort:" : "Num 1:" }}</b>
              </label>
            </div>
            <number-input name="num1" class="col-4 my-2" type="text" v-model="typeCode.num1" />
            <div class="col-2 my-2">
              <label for="num2">
                <b>Num 2:</b>
              </label>
            </div>
            <number-input name="num2" class="col-4 my-2" type="text" v-model="typeCode.num2" />
          </div>

          <div class="row my-2">
            <div class="col-2">
              <label for="flag3">
                <b>{{ flag3Label }}:</b>
              </label>
            </div>
            <div class="col-4 d-flex" v-if="typeCode.typeId === 104">
              <SelectInput name="flag3" :items="diagnosisColors" v-model="typeCode.flag3" />
              <span
                v-if="typeCode.flag3"
                :class="{
                  [`bg-${typeCode.flag3}`]: Boolean(typeCode.flag3),
                  'align-self-end rounded text-white mx-1 p-1 text-sm': true
                }"
                >{{ typeCode.displayName || "Color" }}</span
              >
            </div>
            <div class="col-4" v-if="typeCode.typeId === 103">
              <SelectInput name="flag3" :items="editableByOptions" v-model="typeCode.flag3" />
            </div>
            <TextInput v-else name="flag3" class="col-4" type="text" v-model="typeCode.flag3" />
            <div class="col-2">
              <label for="flag4">
                <b>Flag 4:</b>
              </label>
            </div>
            <TextInput name="flag4" class="col-4" type="text" v-model="typeCode.flag4" />
          </div>
          <div class="row my-2">
            <div class="col-2">
              <label for="flag5">
                <b>Flag 5:</b>
              </label>
            </div>
            <TextInput name="flag5" class="col-4" type="text" v-model="typeCode.flag5" />
            <div class="col-2">
              <label for="flag6">
                <b>Flag 6:</b>
              </label>
            </div>
            <TextInput name="flag6" class="col-4" type="text" v-model="typeCode.flag6" />
          </div>
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <div>
              <button class="btn btn-primary mx-2" type="submit">Save</button>
              <button class="btn btn-danger mx-2" @click="cancelEdit" type="button">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </container>
</template>

<script>
import DxGridWithSearch from "@/components/common/DxGridWithSearch";
import SettingsService from "../services/settings";
import ReportService from "../services/Reports";
import Modal from "@/components/common/Modal";
import { mapState, mapGetters } from "vuex";
import DropdownService from "../services/dropdown";
import {
  capitalize,
  createRegExFromMatchers,
  createLogItem,
  dateRangeFilter,
  altKey,
  bitEnumToArray
} from "../modules/helpers";
import { cloneDeep } from "lodash";
import SlideImagesLinkHandler from "@/components/SlideImageLinksHandler";
import List from "@/components/common/List";
import { debounceTime, distinctUntilChanged, map, switchMap, take, tap } from "rxjs/operators";
import { combineLatest, fromEvent } from "rxjs";
import { required, minValue } from "vuelidate/lib/validators";
import { BillingApi, SettingsApi, AuditLogApi, DropdownApi, PrintersApi } from "@/services/index";
import DataSource from "devextreme/data/data_source";
import Loader from "@/components/common/Loader.vue";
import SimpleSelectInput from "@/components/common/SimpleSelectInput.vue";
import Container from "@/components/common/Container.vue";
import IconButton from "@/components/common/IconButton.vue";
import {
  enumToDropDown,
  fontSizes,
  fontWhiteList,
  MacroWindowClinicalEnum,
  TypeCodeGroupEnum
} from "@/modules/enums";
import TagInput from "@/components/common/TagInput.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import EditorFormatLabSettingInput from "@/components/EditorFormatLabSettingInput.vue";
import { getStyles } from "@/modules/getDefaultStylesByField";
import TextInput from "@/components/common/TextInput.vue";
import NumberInput from "@/components/common/NumberInput.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import Timepicker from "@/components/common/TimePicker.vue";
import EmailsInput from "@/components/common/EmailsInput.vue";
import SpellCheckOptionsInput from "@/components/SpellCheckOptionsInput";
import DisableToolbarLabSetting from "@/components/DisableToolbarLabSetting";
import PrintFileDropLabSettings from "@/components/forms/PrintFileDropLabSettings.vue";
import moment from "moment";
import CoverPageComments from "@/components/CoverPageComments";
import AccessionHistoryLabSetting from "@/components/AccessionHistoryLabSetting.vue";
import EditProstateSiteMap from "@/components/EditProstateSiteMap.vue";

export default {
  name: "Lab-Settings",
  components: {
    DxGridWithSearch,
    Modal,
    List,
    Loader,
    SimpleSelectInput,
    Container,
    IconButton,
    TagInput,
    SelectInput,
    EditorFormatLabSettingInput,
    TextInput,
    NumberInput,
    EmailsInput,
    DatePicker,
    Timepicker,
    SlideImagesLinkHandler,
    SpellCheckOptionsInput,
    DisableToolbarLabSetting,
    PrintFileDropLabSettings,
    CoverPageComments,
    AccessionHistoryLabSetting,
    EditProstateSiteMap
  },
  metaInfo: {
    title: "Settings",
    titleTemplate: "IntelliPath - %s"
  },
  created() {
    DropdownService.getTypeCodeGroups().then(res => {
      this.typeGroupsFilter =
        [
          { displayName: "All", itemId: null },
          ...res.map(e => ({ displayName: e.displayName, itemId: e.id }))
        ] || [];
      this.typeGroups = res || [];
    });
    this.$store.dispatch("dropdowns/getPriorities");
    DropdownService.getBillingRates().then(res => {
      this.defaultBillingRates = res || [];
    });
    PrintersApi.getLabPrinters().then(res => {
      if (this.FileDropPrinting) {
        const { printerName } = this.FileDropPrinting;
        res.push({
          printerId: parseInt(process.env.VUE_APP_FILE_DROP_PRINTER_ID),
          displayName: `File Drop Printer (${printerName})`
        });
      }
      this.labPrinters = res || [];
    });
    DropdownService.searchPathologists.load().then(res => {
      this.pathologists = res;
    });
    DropdownService.getBillingCycles().then(res => {
      this.billingCycleOptions = res || [];
    });
    this.reportStore.load().then(reports => {
      this.labReports = reports;
    });
    DropdownApi.getTaskPriorities().then(res => {
      this.taskPriorities = res;
    });
    DropdownApi.getTags().then(res => {
      this.tagNoteIdOptions = res;
    });
  },
  validations() {
    return {
      labSetting: {
        groupId: {
          required,
          mininum: minValue(1)
        }
      }
    };
  },
  domStreams: ["search$", "typesGrid$"],
  subscriptions() {
    const typesGrid$ = this.typesGrid$.pipe(map(data => data.event.msg));

    const searchValue$ = this.search$.pipe(
      take(1),
      switchMap(({ event }) => {
        const { element } = event.msg;
        const searchElement = element.querySelector("input[type='text']");
        return fromEvent(searchElement, "input");
      }),
      distinctUntilChanged(),
      map(event => event.target.value)
    );
    const searchGrid$ = combineLatest([typesGrid$, searchValue$]).pipe(
      debounceTime(500),
      tap(([typeGrid, searchValue]) => {
        typeGrid.component.searchByText(searchValue);
      })
    );
    return { searchGrid$ };
  },
  data() {
    return {
      fontSizeValues: fontSizes,
      fontFamilyValues: fontWhiteList,
      defaultBillingRates: [],
      originalLabSetting: {},
      originalTypeCode: {},
      labReports: [],
      labPrinters: [],
      typeGroupsFilter: [],
      editor: {},
      toolbar: {
        items: [{ location: "after", template: "extraActions" }]
      },
      cptCodes: new DataSource({
        store: BillingApi.cptPairsStore,
        filter: dateRangeFilter()
      }),
      searchPanel: {
        visible: false
      },
      labSettings: SettingsApi.labSettingsSearch,
      typeCodes: SettingsApi.typeCodesSearch,
      isEditing: false,
      isLabEdit: false,
      isLabAdminEdit: false,
      isTypeAdminEdit: false,
      reportStore: ReportService.searchStore,
      groupsSearch: SettingsService.LabSettingGroupsSearch,
      isTypeEdit: false,
      isConfirmDelete: false,
      isLoading: false,
      hasSelection: false,
      dropdownOptions: {
        position: "bottom"
      },
      batchModes: [
        {
          displayName: "Fax",
          id: 1
        },
        {
          displayName: "Email",
          id: 2
        },
        {
          displayName: "Print",
          id: 3
        },
        {
          displayName: "HL7",
          id: 4
        },
        {
          displayName: "FileDropPrintingConfiguration",
          id: 5
        }
      ],
      booleanOptions: [
        { id: 1, displayName: "On" },
        { id: 0, displayName: "Off" }
      ],
      diagnosisColors: [
        {
          id: "DR",
          displayName: "DARK RED"
        },
        {
          id: "R",
          displayName: "RED"
        },
        {
          id: "O",
          displayName: "ORANGE"
        },
        {
          id: "Y",
          displayName: "YELLOW"
        },
        {
          id: "LG",
          displayName: "LIGHT GREEN"
        },
        {
          id: "G",
          displayName: "GREEN"
        },
        {
          id: "LB",
          displayName: "LIGHT BLUE"
        },
        {
          id: "B",
          displayName: "BLUE"
        },
        {
          id: "DB",
          displayName: "DARK BLUE"
        },
        {
          id: "P",
          displayName: "PURPLE"
        }
      ],
      billingTypeOptions: [
        { id: 0, displayName: "Patient" },
        { id: 1, displayName: "Doctor" }
      ],
      priorityOptions: [
        { id: 1, displayName: "High" },
        { id: 2, displayName: "Medium" },
        { id: 3, displayName: "Low" },
        { id: 4, displayName: "Rush" }
      ],
      newYearSeedOptions: [
        { id: 1, displayName: "On" },
        { id: 0, displayName: "Off" }
      ],
      specimenNumberingOptions: [
        { id: 1, displayName: "123" },
        { id: 2, displayName: "ABC" }
      ],
      accessionNumberingOptions: [
        { id: 0, displayName: "Prefix" },
        { id: 1, displayName: "Pathology" }
      ],
      defaultDateOptions: [
        { id: 0, displayName: "Current Date" },
        { id: 1, displayName: "Receive Date" },
        { id: 2, displayName: "Blank" }
      ],
      defaultPrintModes: [
        {
          displayName: "Initial",
          id: 1
        },
        {
          displayName: "Re-Distribute",
          id: 2
        }
      ],
      defaultSlideModes: [
        {
          displayName: "Cassettes",
          id: 1
        },
        {
          displayName: "Slides",
          id: 2
        },
        {
          displayName: "Slides (Glass)",
          id: 3
        },
        {
          displayName: "Worksheet",
          id: 4
        }
      ],
      defaultBatchCopyNamesMode: [
        {
          displayName: "Lab",
          id: 1
        },
        {
          displayName: "Secondary Provider",
          id: 2
        },
        {
          displayName: "Copy Names Type Code",
          id: 4
        }
      ],
      selectedRows: [],
      selection: {
        mode: "multiple",
        allowSelectAll: true,
        showCheckBoxesMode: "always",
        selectAllMode: "page"
      },
      types: [],
      labSetting: {},
      typeCode: {},
      selectedType: null,
      selectedReport: null,
      typeGroups: [],
      availableLabs: [],
      pathologists: [],
      accessionArrangements: [
        { id: 0, displayName: "Quick" },
        { id: 1, displayName: "Grouped" }
      ],
      procedureSearches: [
        { id: 0, displayName: "Code" },
        { id: 1, displayName: "Description" }
      ],
      saveShortKey: altKey("s"),
      billingCycleOptions: [],
      specimenIcdOptionalOptions: [
        {
          id: 0,
          displayName: "Off"
        },
        {
          id: 1,
          displayName: "Warning"
        },
        {
          id: 2,
          displayName: "On"
        }
      ],
      mrnMatchingOptions: [
        {
          id: 1,
          displayName: "Never"
        },
        {
          id: 2,
          displayName: "Prefix"
        },
        {
          id: 3,
          displayName: "Always"
        }
      ],
      hl7ContactFilterOptions: [
        {
          id: 0,
          displayName: "NPI"
        },
        {
          id: 1,
          displayName: "Legacy ID"
        }
      ],
      fileExtensionOptions: [
        { id: 0, displayName: "png" },
        { id: 1, displayName: "pdf" },
        { id: 2, displayName: "tif" }
      ],
      taskPriorities: [],
      specimenFocusOptions: [
        { id: 0, displayName: "Protocol" },
        { id: 1, displayName: "Site" },
        { id: 2, displayName: "Measurement" }
      ],
      editableByOptions: [
        { id: 0, displayName: "Anyone" },
        { id: 1, displayName: "Creator" },
        { id: 2, displayName: "Creator or with Permission" }
      ],
      tagNoteTypeOptions: [
        { id: 0, displayName: "Tag", valueName: "T" },
        { id: 1, displayName: "Note", valueName: "N" }
      ],
      tagNoteIdOptions: []
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapState({
      defaultPriorityOptions: state => state.dropdowns.priorities,
      FileDropPrinting: state => JSON.parse(state.labSettings.FileDropPrintingConfiguration)
    }),
    ...mapGetters(["permissions"]),
    typesColumns() {
      return [
        {
          dataField: "typeId",
          dataType: "number",
          caption: "Type",
          lookup: {
            dataSource: this.typeGroups,
            displayExpr: "displayName",
            valueExpr: "id"
          },
          allowSearch: false,
          filterValue: this.selectedType ? Number(this.selectedType) : null
        },
        {
          dataField: "code",
          caption: "Code",
          dataType: "string"
        },
        {
          dataField: "displayName",
          caption: "Display Name",
          dataType: "string",
          calculateCellValue: this.calculateDisplayName
        },
        {
          dataField: "flag2",
          caption: "Flag 2",
          dataType: "string"
        },
        {
          dataField: "flag1",
          caption: "Flag 1",
          dataType: "string"
        },
        {
          dataField: "flag3",
          caption: "Flag 3",
          dataType: "string"
        },
        {
          dataField: "flag4",
          caption: "Flag 4",
          dataType: "string"
        },
        {
          dataField: "flag5",
          caption: "Flag 5",
          dataType: "string"
        },
        {
          dataField: "flag6",
          caption: "Flag 6",
          dataType: "string"
        },
        {
          dataField: "date1",
          caption: "Date 1",
          dataType: "date"
        },
        {
          dataField: "date2",
          caption: "Date 2",
          dataType: "date"
        },
        {
          dataField: "keywords",
          caption: "Keywords",
          dataType: "string"
        },
        {
          dataField: "description",
          caption: "Description",
          dataType: "string"
        },
        {
          type: "buttons",
          cellTemplate: "actions-cell",
          caption: "Actions",
          visible: this.permissions.SettingCreateEdit || this.permissions.SettingAdmin
        }
      ];
    },
    labSettingValueProps() {
      const props = { items: [], is: "SelectInput", value: this.labSetting.value, name: "value" };
      if (this.usesReportStore(this.labSetting.settingDisplayName)) {
        return {
          items: this.labReports,
          valueExpr: "id",
          displayExpr: "name",
          is: "SelectInput"
        };
      }
      switch (this.labSetting?.settingType) {
        case 0:
          props.is = "SelectInput";
          props.items = this.booleanOptions;
          break;
        case 1:
          props.is = "TextInput";
          break;
        case 2:
          props.is = "NumberInput";
          break;
        case 3:
          props.is = "SelectInput";
          break;
      }
      const displayName = this.labSetting?.settingDisplayName?.replace(/\s+/gi, "");
      if (/time(?!out)/i.test(displayName)) {
        props.is = "Timepicker";
      }
      if (/SpellCheckOptions/i.test(displayName)) {
        props.is = "SpellCheckOptionsInput";
      }
      if (/MacroWindowClinical/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = enumToDropDown(MacroWindowClinicalEnum);
      }
      if (/DefaultFont/i.test(displayName)) {
        props.is = "EditorFormatLabSettingInput";
      }
      if (/defaultpriority/i.test(displayName)) {
        props.items = this.defaultPriorityOptions;
      }
      if (/DefaultDateCollected/i.test(displayName)) {
        props.items = this.defaultDateOptions;
      }
      if (/accessionnumbering/i.test(displayName)) {
        props.items = this.accessionNumberingOptions;
      }
      if (/billingType/i.test(displayName)) {
        props.items = this.billingTypeOptions;
      }
      if (/billingRate/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = this.defaultBillingRates;
      }
      if (/(?<!welcome)emails/i.test(displayName)) {
        props.is = EmailsInput;
      }
      if (/specimenNumberingTypes/i.test(displayName)) {
        props.items = this.specimenNumberingOptions;
      }
      if (/orderNumberMatcher/i.test(displayName)) {
        props.validationMessage = `Please write an example and wrap the order number with "{}",and mark the number with "X". Use "|" to sperate multiple matcher expressions.`;
      }
      if (/DefaultPathologistId/i.test(displayName)) {
        props.dataSource = DropdownApi.searchPathologists;
        props.is = "SelectInput";
      }

      if (/DefaultEditorFontFamily/i.test(displayName)) {
        props.items = this.fontFamilyValues;
        props.is = "SelectInput";
        props.displayExpr = "";
        props.valueExpr = "";
      }
      if (/DefaultBatchMessageType/i.test(displayName)) {
        props.items = this.batchModes;
      }
      if (/DefaultSlidePrintMode/i.test(displayName)) {
        props.items = this.defaultSlideModes;
      }
      if (/(?<!Focus)Printer/i.test(displayName)) {
        props.items = this.labPrinters;
        props.is = "SelectInput";
        if (/defaultprinter/i.test(displayName)) {
          props.is = "TagInput";
          props.displayExpr = "displayName";
          props.valueExpr = "printerId";
        }
      }
      if (/DefaultCopyNamesMode/i.test(displayName)) {
        props.items = this.defaultBatchCopyNamesMode;
        props.is = "TagInput";
      }

      if (/labBinPrintOrder/i.test(displayName)) {
        props.is = "List";
        if (!props.value) {
          props.defaultValue = "prefix,accession,protocol,provider";
          props.value = "prefix,accession,protocol,provider";
        }
      }
      if (/accessioningArrangement/i.test(displayName)) {
        props.items = this.accessionArrangements;
        props.is = "SelectInput";
      }
      if (/toolbars/i.test(displayName)) {
        props.is = "DisableToolbarLabSetting";
      }
      if (/ProcedureSearchBy/i.test(displayName)) {
        props.items = this.procedureSearches;
        props.is = "SelectInput";
      }
      if (/FileDropPrintingConfiguration/i.test(displayName)) {
        props.is = "PrintFileDropLabSettings";
      }
      if (/billcycle/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = this.billingCycleOptions;
      }
      if (/cover/i.test(displayName)) {
        props.is = "CoverPageComments";
      }
      if (/icdoptional/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = this.specimenIcdOptionalOptions;
      }
      if (/hl7contactfilter/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = this.hl7ContactFilterOptions;
      }
      if (/MatchingOnMRN/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = this.mrnMatchingOptions;
      }
      if (/SlideImagesApplicationHandler/i.test(displayName)) {
        props.is = "SlideImagesLinkHandler";
      }

      // IP-1484 - replace with more granular test when lab setting created
      if (/history/i.test(displayName)) {
        props.is = "AccessionHistoryLabSetting";
      }

      if (/clienttaskpriority/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = this.taskPriorities;
      }
      if (/specimenfocus/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = this.specimenFocusOptions;
      }
      if (/DefaultEditorFontSize/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = fontSizes.map((e, idx) => {
          return { id: idx, displayName: e };
        });
        props.valueExpr = "displayName";
      }
      if (/ProstateSite/i.test(displayName)) {
        props.is = "EditProstateSiteMap";
      }
      if (/DefaultEditorFontSize/i.test(displayName)) {
        props.is = "SelectInput";
        props.items = fontSizes.map((e, idx) => {
          return { id: idx, displayName: e };
        });
        props.valueExpr = "displayName";
      }
      if (/RegistrationNoteTag/i.test(displayName)) {
        props.is = SelectInput;
        props.items = this.tagNoteIdOptions;
      }

      return props;
    },
    columns() {
      return [
        {
          sortIndex: 0,
          sortOrder: "desc",
          dataField: "group.displayName",
          caption: "Group",
          dataType: "string",
          calculateCellValue(data) {
            return data.group ? data?.group?.displayName : "N/A";
          }
        },
        {
          dataField: "settingDisplayName",
          caption: "Setting",
          dataType: "string"
        },
        {
          dataField: "isPrivate",
          caption: "Private",
          dataType: "boolean"
        },
        {
          dataField: "keywords",
          caption: "Keywords",
          dataType: "string"
        },
        {
          dataField: "description",
          caption: "Description",
          dataType: "string"
        },
        {
          dataField: "value",
          caption: "Value",
          dataType: "string",
          encodeHtml: false,
          calculateCellValue: this.calculateValue
        },
        {
          type: "buttons",
          cellTemplate: "actions-cell",
          caption: "Actions",
          visible: this.permissions.SettingCreateEdit || this.permissions.SettingAdmin
        }
      ];
    },
    settingsGrid() {
      return this.$refs?.settingsGrid?.grid;
    },
    typesGrid() {
      return this.$refs?.typesGrid?.grid;
    },
    hasEffectiveOrExpiry() {
      return [TypeCodeGroupEnum.HoldCode, TypeCodeGroupEnum.Tag].includes(this.typeCode.typeId);
    },
    useLargeContainer() {
      return /Prostate Site/i.test(this.labSetting.settingDisplayName);
    },
    flag1Label() {
      switch (this.typeCode?.typeId) {
        case TypeCodeGroupEnum.Tag:
          return "Type";
        case TypeCodeGroupEnum.DiagnosisSummary:
          return "Diagnosis Color";
        default:
          return "Flag 1";
      }
    },
    flag3Label() {
      switch (this.typeCode?.typeId) {
        case TypeCodeGroupEnum.Tag:
          return "Editable by";
        default:
          return "Flag 3";
      }
    }
  },
  watch: {
    "typeCode.typeId"(nv) {
      if (this.typeCode.type != nv) {
        const type = this.typeGroups.find(e => Number(e.id) === Number(nv));
        if (type) {
          this.typeCode.type = type;
        }
      }
    },
    selectedReport(nv) {
      if (nv) {
        if (this.isLabEdit) {
          if (nv?.id != this.labSetting.value) {
            this.labSetting.value = nv.id;
          }
        }
      }
    }
  },
  methods: {
    closeConfirmDelete() {
      this.isConfirmDelete = false;
    },
    async handleDelete({ data }) {
      const confirm = await window.confirm(`Are you sure you want to delete this type code?`);
      if (confirm) {
        const typeCodeforDeletion = {
          typeCodeGroupId: data.typeId,
          typeCodeId: data.id
        };
        return SettingsService.deleteTypeCode(typeCodeforDeletion).then(res => {
          if (res) {
            this.typesGrid.refresh(true);
          }
        });
      }
      return;
    },

    async handleRefresh() {
      await this.$store.dispatch("labSettings/getLabSettings");
      this.settingsGrid.refresh();
      this.typesGrid.refresh();
    },
    setSelected({ selectedRowKeys }) {
      this.hasSelection = Boolean(selectedRowKeys.length);
    },
    editSetting({ data }) {
      this.labSetting = {
        ...data
      };
      this.originalLabSetting = cloneDeep(data);
      if (this.usesReportStore(data.settingDisplayName)) {
        this.reportStore.byKey(data.value).then(res => {
          this.selectedReport = res;
        });
      }
      if (/Default\sPrinter/i.test(data.settingDisplayName) && this.labSetting?.value) {
        this.labSetting.value = this.labSetting.value.split(",").map(e => Number(e));
      }
      if (/(?<!welcome\s)emails/i.test(data.settingDisplayName) && this.labSetting?.value) {
        this.labSetting.value = this.labSetting.value.split(",").map(e => ({ address: e }));
      }
      if (/Default\sFont/i.test(data.settingDisplayName) && this.labSetting?.value) {
        this.labSetting.value = JSON.parse(this.labSetting.value);
      }
      if (/Default\sCopy\sNames/i.test(data.settingDisplayName) && this.labSetting?.value) {
        this.labSetting.value = this.formatCopyModeSetting(this.labSetting.value);
      }
      this.isLabEdit = true;
    },
    editAdminSetting({ data }) {
      this.isLabAdminEdit = true;
      this.labSetting = {
        ...data
      };
    },
    editTypeCode(data) {
      this.isTypeEdit = true;
      this.typeCode = data.data;
    },
    editTypeCodeAdmin(data) {
      this.isTypeAdminEdit = true;
      this.typeCode = data.data;
    },
    newTypeCode() {
      // const typeCode = this.typeGroups.find(e => e.id === Number(this.selectedType));
      this.typeCode = {
        labId: this.currentLab,
        typeId: this.selectedType,
        code: "",
        displayName: "",
        flag1: "",
        flag2: "",
        flag3: "",
        flag4: "",
        flag5: "",
        flag6: ""
      };
      doubleRaf(() => {
        this.isTypeEdit = true;
      });
    },
    cancelEdit() {
      this.isEditing = false;
      this.isLoading = false;
      this.isLabEdit = false;
      this.isLabAdminEdit = false;
      this.isTypeEdit = false;
      this.isTypeAdminEdit = false;
    },
    async saveLabSetting() {
      if (this.$v.$invalid) {
        window.notify("Please select a group for this lab setting", "error");
        return;
      }
      if (!this.currentLab) {
        window.notify("No lab selected.", "warning");
        return;
      }

      const isBool = this.labSetting.settingType === 0;
      let newVal = this.labSetting.value;
      if (isBool) {
        newVal = newVal ? "1" : "0";
      }

      const body = {
        ...this.labSetting,
        value: newVal,
        labId: this.currentLab
      };
      const displayName = this.labSetting.settingDisplayName.replace(/\s+/gi, "");
      if (/orderNumberMatcher/i.test(displayName)) {
        let { value } = this.labSetting;
        const matchers = createRegExFromMatchers(value);
        if (
          !(await window.confirm(
            `Found ${matchers.length} ${
              matchers.length > 1 ? "matches" : "match"
            }. <br> Is this correct?`
          ))
        ) {
          return;
        }
        body.value = value;
      }
      if (/defaultprinters/i.test(displayName) && newVal) {
        body.value = newVal.join(",").toString();
      }
      if (/defaultfont/i.test(displayName) && newVal) {
        body.value = JSON.stringify(newVal);
      }
      if (/defaultcopynames/i.test(displayName) && newVal) {
        if (!newVal?.length) {
          body.value = null;
        } else {
          body.value = newVal.reduce((a, b) => a + b);
        }
      }
      if (/(?<!welcome)emails/i.test(displayName) && Array.isArray(newVal)) {
        body.value = newVal.map(e => e?.address).join(",");
      }
      if (/DefaultEditorFontSize/i.test(displayName)) {
        if (!/^\d{1,2}pt$/.test(body.value)) {
          let number = body.value.replaceAll(/\D/g, "");
          if (number) {
            number += "pt";
            if (!fontSizes.includes(number)) {
              window.alert(
                `Font size ${number} is not valid. Acceptable values are ${fontSizes.join(", ")}.`
              );
              return;
            }
            body.value = number;
          } else {
            window.alert(
              `Value "${body.value}" is not valid. Please enter a number followed by "pt" (e.g. "11pt")`
            );
            return;
          }
        }
      }

      try {
        if (this.isLabAdminEdit) {
          const adminBody = {
            settingId: body.settingId,
            isPrivate: body.isPrivate,
            keywords: body.keywords,
            description: body.description,
            groupId: body.groupId
          };
          await SettingsService.editAdminSetting(adminBody);
        } else {
          await SettingsService.editLabSettingsGrid(body);
        }
        const logItem = createLogItem({}, 5);
        if (this.originalLabSetting.value !== newVal) {
          logItem.comments = `\n Changed ${this.labSetting.settingDisplayName} from ${this.originalLabSetting.value} to ${newVal}`;
          await AuditLogApi.insertLogMessage(logItem);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isEditing = false;
        this.isLoading = false;
        this.isLabEdit = false;
        this.isTypeEdit = false;
        this.isLabAdminEdit = false;
        this.isTypeAdminEdit = false;
        this.handleRefresh();
      }
    },
    saveTypeCode() {
      if (!this.currentLab) {
        window.notify("No lab selected.", "warning");
        return;
      }
      const handlePromise = () => {
        this.isEditing = false;
        this.isLoading = false;
        this.isLabEdit = false;
        this.isTypeEdit = false;
        this.isLabAdminEdit = false;
        this.isTypeAdminEdit = false;
        return this.handleRefresh();
      };
      if (this.typeCode.id != undefined) {
        SettingsService.editTypeCode(this.typeCode)
          .then(handlePromise)
          .catch(error => {
            if (error?.response?.data) {
              window.notify(error?.response?.data, "error");
            } else {
              window.notify("Error occurred with your submission.", "error");
            }
          });
      } else {
        SettingsService.addTypeCode(this.typeCode)
          .then(handlePromise)
          .catch(error => {
            if (error?.response?.data) {
              window.notify(error?.response?.data, "error");
            } else {
              window.notify("Error occurred with your submission.", "error");
            }
          });
      }
    },
    calculateValue(data) {
      if (data) {
        const { settingDisplayName, value, settingType } = data;
        const displayName = settingDisplayName?.replace(/\s+/gi, "");
        if (/file drop printing configuration/i.test(settingDisplayName)) {
          return value ? "Click edit to view settings" : "Not enabled";
        }
        if (/time(?!out)/i.test(settingDisplayName)) {
          if (value) {
            return this.utcformat(value);
          }
          return "";
        }
        if (/Default\sFont\s/i.test(settingDisplayName)) {
          return `<span style="${getStyles(JSON.parse(value || "{}"))}">Example Text</span>`;
        }
        if (/toolbars/i.test(settingDisplayName) && value) {
          return value
            .replaceAll(",", ", ")
            .replace(/^notes|\Wnotes/, "specimen notes")
            .replace("caseNotes", "case notes");
        }
        if (/labBinPrintOrder/i.test(displayName) && value) {
          return value.split(",").map(capitalize).join(",");
        }
        //Items is passed to the devExtreme to use as a lookup for certain lab settings.
        let items = [];
        if (/billingRate/i.test(displayName)) {
          items = this.defaultBillingRates;
        }
        if (/MacroWindowClinical/i.test(displayName)) {
          items = enumToDropDown(MacroWindowClinicalEnum);
        }
        if (/defaultBatchMessageType/i.test(displayName)) {
          items = this.batchModes;
        }
        if (/DefaultPrinters/i.test(displayName) && value) {
          return value
            .split(",")
            .map(e => {
              const printerName = this.labPrinters.find(j => j.printerId === Number(e));
              return printerName?.displayName;
            })
            .join(", ");
        }
        if (settingType === 3) {
          if (/defaultpriority/i.test(displayName)) {
            items = this.defaultPriorityOptions;
          }

          if (/(?<!Focus)printer/i.test(displayName)) {
            items = this.labPrinters;
          }
          if (/DefaultDateCollected/i.test(displayName)) {
            items = this.defaultDateOptions;
          }
          if (/accessionnumberingtype/i.test(displayName)) {
            items = this.accessionNumberingOptions;
          }
          if (/billingType/i.test(displayName)) {
            items = this.billingTypeOptions;
          }
        }
        if (/DefaultBatchMessageType/i.test(displayName)) {
          items = this.batchModes;
        }
        if (/DefaultSlidePrintMode/i.test(displayName)) {
          items = this.defaultSlideModes;
        }
        if (/(?<!Focus)Printer/i.test(displayName)) {
          items = this.labPrinters;
        }
        if (/DefaultCopyNamesMode/i.test(displayName)) {
          items = this.defaultBatchCopyNamesMode;
        }
        if (/specimenNumberingTypes/i.test(displayName)) {
          items = this.specimenNumberingOptions;
        }
        if (/DefaultPathologistId/i.test(displayName)) {
          items = this.pathologists;
        }
        if (this.usesReportStore(displayName)) {
          items = this.labReports;
        }
        if (/accessioningArrangement/i.test(displayName)) {
          items = this.accessionArrangements;
        }
        if (/ProcedureSearchBy/i.test(displayName)) {
          items = this.procedureSearches;
        }
        if (/billcycle/i.test(displayName)) {
          items = this.billingCycleOptions;
        }
        if (/icdOptional/i.test(displayName)) {
          items = this.specimenIcdOptionalOptions;
        }
        if (/hl7contactfilter/i.test(displayName)) {
          if (!parseInt(value)) {
            return "NPI";
          }
          items = this.hl7ContactFilterOptions;
        }
        if (/MatchingOnMRN/i.test(displayName)) {
          items = this.mrnMatchingOptions;
        }
        if (/SlideImagesApplicationHandler/i.test(displayName) && typeof value === "string") {
          try {
            const details = JSON.parse(value);
            if (details.target == "_blank") {
              return `Open a new tab`;
            }
            if (details.target == "_self") {
              return `Open in the same window`;
            }
            if (details.target == "slideImageWindow") {
              const { width, height } = details;
              return `Open a new ${width}x${height} window`;
            }
            if (details.protocol == "mview") {
              return "Open in the 3d Slide Viewer application";
            }
          } catch (error) {
            console.error("Error determining slide open handler", error);
          }
        }
        if (/defaultcopynames/i.test(displayName)) {
          if (!value || value === "0") {
            return "None";
          }
          let displayNames = [];
          const valueArray = this.formatCopyModeSetting(value);
          for (const nameIndex of valueArray) {
            const item = this.defaultBatchCopyNamesMode.find(e => e.id === parseInt(nameIndex));
            displayNames.push(item.displayName);
          }
          return displayNames.join(", ");
        }
        if (/patienthistoryfields/i.test(displayName) && value) {
          return "Click edit to view setting.";
        }
        if (/clienttaskpriority/i.test(displayName)) {
          items = this.taskPriorities;
        }
        if (/specimenfocus/i.test(displayName)) {
          items = this.specimenFocusOptions;
        }
        if (/ProstateSite/i.test(displayName) && value) {
          return "Click to edit or view settings.";
        }
        if (/RegistrationNoteTag/i.test(displayName)) {
          items = this.tagNoteIdOptions;
        }
        if (items.length) {
          const item = items.find(e => new RegExp(value, "i").test(e.id));
          return item?.displayName || item?.name || item?.code || "";
        }
        if (settingType === 0) {
          return value && parseInt(value) ? "On" : "Off";
        }
      }
      return data.value;
    },
    calculateFax(data) {
      if (data?.properties?.faxMachines?.length) {
        const primary = data.properties.faxMachines.find(e => e.primary);
        return primary ? primary.phoneNumber : "N/A";
      }
      return "N/A";
    },
    calculateDisplayName(data) {
      return data?.displayName || "N/A";
    },

    calculatePhone(data) {
      return data?.provider?.phoneNumber || "N/A";
    },
    providerNameValue(data) {
      return data?.provider?.lastName + "," + data?.provider?.firstName;
    },
    locationNameValue(data) {
      return data?.location?.name;
    },
    providerName({ data }) {
      return data?.provider?.lastName + "," + data?.provider?.firstName;
    },
    locationName({ data }) {
      return data?.location?.name;
    },
    directRouter(path, params = {}) {
      return this.$router.push({
        name: path,
        params: {
          shouldRedirect: true,
          ...params
        }
      });
    },
    utcformat(d) {
      if (d) {
        return moment.tz(d, "UTC").format("hh:mm a");
      }
      return "";
    },
    changeJSON(value) {
      this.labSetting.value = value;
    },
    formatCopyModeSetting(value) {
      if (value === null) {
        return null;
      }
      return bitEnumToArray(value);
    },
    usesReportStore(displayName) {
      if (!displayName) {
        return false;
      }
      displayName = displayName.replaceAll(" ", "");
      return /Report|Template/i.test(displayName) && !/use|name|supports|zip/i.test(displayName);
    }
  }
};
function doubleRaf(callback) {
  return requestAnimationFrame(() => {
    requestAnimationFrame(callback);
  });
}
</script>

<style lang="scss" scoped>
.labSettingsContainer {
  max-width: 500px;
  width: 500px;
  height: 300px;
}
.largeLabSettingsContainer {
  width: 750px;
}
.edit-btn {
  transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nav-link {
  text-transform: capitalize;
  font-weight: 500;
}
.text-sm {
  font-size: 0.75rem;
}
.row {
  align-items: center;
}
.text-input {
  min-height: initial;
}
#propertiesForm {
  width: 40%;
  & > .row:nth-child(even) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
